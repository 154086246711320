<template>
    <div>
        <div class="das_top_cont">
            <ul class="das_top_list flex_center_between_box">
                <li class="das_top_item">
                    <div class="item_img color1">
                        <img src="../../assets/img/account/visit.png" />
                    </div>
                    <div class="item_title">Logins</div>
                    <div class="item_group">
                        <label class="item_lab">Last login time:</label>
                        <div class="item_txt">{{ obj.LastLoginDuration }}</div>
                    </div>
                    <div class="item_group">
                        <label class="item_lab">Total logins:</label>
                        <div class="item_txt">{{ obj.TotalLogins }}</div>
                    </div>
                    <div class="item_group">
                        <label class="item_lab">Average login time:</label>
                        <div class="item_txt">{{ obj.AverageDuration }}</div>
                    </div>
                </li>
                <li class="das_top_item">
                    <div class="item_img color2">
                        <img src="../../assets/img/account/use.png" />
                    </div>
                    <div class="item_title">Usage</div>
                    <div class="item_group">
                        <label class="item_lab"># of co viewed last login:</label>
                        <div class="item_txt">{{ obj.LastViewedQuantity }}</div>
                    </div>
                    <div class="item_group">
                        <label class="item_lab"># of co viewed this month:</label>
                        <div class="item_txt">{{ obj.QuantityOfThisMonth }}</div>
                    </div>
                    <div class="item_group">
                        <label class="item_lab">Total # of co viewed:</label>
                        <div class="item_txt">{{ obj.TotalViews }}</div>
                    </div>
                </li>
                <li class="das_top_item">
                    <div class="item_img color3">
                        <img src="../../assets/img/account/update.png" />
                    </div>
                    <div class="item_title">Orders</div>
                    <div class="item_group">
                        <label class="item_lab"># of orders last login:</label>
                        <div class="item_txt">{{ obj.LastUpdatedQuantity }}</div>
                    </div>
                    <div class="item_group">
                        <label class="item_lab"># of orders this month:</label>
                        <div class="item_txt">{{ obj.UpdateQuantityThisMonth }}</div>
                    </div>
                    <div class="item_group">
                        <label class="item_lab">Total # of orders:</label>
                        <div class="item_txt">{{ obj.TotalUpdates }}</div>
                    </div>
                </li>
                <li class="das_top_item">
                    <div class="item_img color4">
                        <img src="../../assets/img/account/recharge.png" />
                    </div>
                    <div class="item_title">Payments</div>
                    <div class="item_group">
                        <label class="item_lab">Last payment:</label>
                        <div class="item_txt">${{ obj.LastRechargeAmount | NumFormat }}</div>
                    </div>
                    <div class="item_group">
                        <label class="item_lab">Total payments:</label>
                        <div class="item_txt">${{ obj.AccumulatedRechargeAmount | NumFormat }}</div>
                    </div>
                    <div class="item_group">
                        <label class="item_lab">Current balance:</label>
                        <div class="item_txt">{{ obj.RemainingQuota }} points</div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="top_tab">
            <el-tabs v-model="tabActiveName" type="card" class="tab_box" @tab-click="handleTabClick">
                <el-tab-pane v-if="obj.tab1 != 0" label="Your Logins" name="LoginAtGlance" class="result_tab">
                    <div class="table_box">
                        <el-table ref="loginAtGlanceTable" :data="loginAtGlanceTable" border style="width: 100%">
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">No Data</template>
                            <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="User name" prop="userName" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="{ path: '/users/background', query: { pid: scope.row.pid, name: scope.row.userName } }">{{ scope.row.userName }}</router-link> -->
                                    <div>{{ scope.row.userName }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Login time" prop="loginTime" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Login IP" prop="requestIp" align="left" show-overflow-tooltip :resizable="false" />
                        </el-table>
                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                            <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane v-if="obj.tab2 != 0" label="Your Usage" name="UsageList" class="result_tab">
                    <div class="table_box">
                        <div class="title_txt flex_center_start_box" style="width: 480px; padding-bottom: 15px;">
                            <label style="display: flex; width: 150px" for="">Find Company</label>
                            <!-- <el-autocomplete class="inline-input" v-model="CategoryVal" :fetch-suggestions="querySearch" placeholder="Company Name"></el-autocomplete> -->
                            <el-input class="title-input" clearable suffix-icon="el-icon-search" v-model="seachinput" label="" placeholder="Search any Keyword in this Usage" @change="inputcompany()"></el-input>
                        </div>
                        <el-table ref="UsageList" :data="UsageList" border style="width: 100%">
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">No Data</template>
                            <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Date" prop="createTime" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="User name" prop="customerName" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <!-- @click="setid2(scope.row)" -->
                                    <!-- <router-link :to="{ path: '/users/background', query: { pid: scope.row.pid, name: scope.row.customerName } }">{{ scope.row.customerName }}</router-link> -->
                                    <div>{{ scope.row.customerName }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Company name" prop="companyName" align="left" show-overflow-tooltip :resizable="false" class-name="company_link" width="330">
                                <template slot-scope="scope">
                                    <div class="line_1" style="width: 310px" @click="setid(scope.row)">
                                        <span>{{ scope.row.companyName }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Units used" prop="amountActual" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Usage type" prop="type" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.type == '1'">Online data</span>
                                    <span v-else-if="scope.row.type == '2'">Offline data</span>
                                    <span v-else-if="scope.row.type == '3'">Balance recharge</span>
                                    <span v-else-if="scope.row.type == '8'">Integral distribution</span>
                                    <span v-else-if="scope.row.type == '13'">Import export</span>
                                    <span v-else-if="scope.row.type == '14'">Person detail</span>
                                    <span v-else-if="scope.row.type == '15'">Country detail export</span>
                                    <span v-else-if="scope.row.type == '16'">Top players export</span>
                                    <span v-else-if="scope.row.type == '17'">Buyers Suppliers</span>
                                    <span v-else-if="scope.row.type == '19'">Shareholder penetration</span>
                                    <span v-else-if="scope.row.type == '20'">News Saction</span>
                                    <span v-else-if="scope.row.type == '21'">Precise Push</span>
                                    <span v-else>other</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page2.total" @onPageChange="onPageChange2" @currtentPageChange="currtentPageChange2"></Page>
                            <div class="search_rsult_txt line_2">{{ page2.pageNo }}-{{ page2.pageSize }} of over {{ page2.total | FilterNum }} results</div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane v-if="obj.tab3 != 0" label="Your Orders" name="OrderList" class="result_tab">
                    <div class="table_box">
                        <el-table ref="OrderList" :data="OrderList" border style="width: 100%">
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">No Data</template>
                            <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Date" prop="createTime" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div class="line_1" style="width: 160px">
                                        <span>{{ scope.row.createTime }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="User name" prop="customerName" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Company name" prop="companyName" align="left" show-overflow-tooltip :resizable="false" class-name="company_link" width="230">
                                <template slot-scope="scope">
                                    <div class="line_1" style="width: 210px" @click="setid(scope.row)">
                                        <span>{{ scope.row.companyName }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Order no." prop="orderCode" align="left" show-overflow-tooltip :resizable="false" />

                            <el-table-column label="Order type " prop="type" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.type == '1'">Online data</span>
                                    <span v-else-if="scope.row.type == '2'">Offline data</span>
                                    <span v-else-if="scope.row.type == '3'">Balance recharge</span>
                                    <span v-else-if="scope.row.type == '8'">Integral distribution</span>
                                    <span v-else-if="scope.row.type == '13'">Import export</span>
                                    <span v-else-if="scope.row.type == '14'">Person detail</span>
                                    <span v-else-if="scope.row.type == '15'">Country detail export</span>
                                    <span v-else-if="scope.row.type == '16'">Top players export</span>
                                    <span v-else-if="scope.row.type == '17'">Buyers Suppliers</span>
                                    <span v-else>Person search results companies</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Status" prop="orderStatus" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.orderStatus == '1'">Unpaid</span>
                                    <span v-else-if="scope.row.orderStatus == '4'">Processing</span>
                                    <span v-else-if="scope.row.orderStatus == '8'">Order Completed</span>
                                    <span v-else-if="scope.row.orderStatus == '0'">Order Canceled</span>
                                    <span v-else>Order Canceled</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Download" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                                <template slot-scope="scope">
                                    <div class="flex_center">
                                        <el-tooltip class="tbl_opr_tip" :visible-arrow="false" effect="light" content="download" placement="top">
                                            <div class="oper_down" @click="hanldeDown(scope.row)" v-if="scope.row.orderStatus == '8'">
                                                <img src="../../assets/img/account/download.png" />
                                            </div>
                                            <div class="oper_down disabled" v-else>
                                                <img src="../../assets/img/account/download.png" />
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page3.total" @onPageChange="onPageChange3" @currtentPageChange="currtentPageChange3"></Page>
                            <div class="search_rsult_txt line_2">{{ page3.pageNo }}-{{ page3.pageSize }} of over {{ page3.total | FilterNum }} results</div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane v-if="obj.tab4 != 0" label="Your Payments" name="RechargeList" class="result_tab">
                    <div class="table_box">
                        <el-table ref="RechargeList" :data="RechargeList" border style="width: 100%">
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">No Data</template>
                            <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Date" prop="createTime" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="User name" prop="customerName" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Payment amount " prop="amountActual" align="right" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">$ {{ scope.row.amountActual }}</template>
                            </el-table-column>
                            <el-table-column label="Download invoice" prop="InvoiceRequest" align="center" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div class="flex_center">
                                        <el-tooltip class="tbl_opr_tip" :visible-arrow="false" effect="light" content="Apply" placement="top">
                                            <div class="oper_down" @click="hanldeDown(scope.row)" v-if="scope.row.orderStatus == '8'">
                                                <img src="../../assets/img/account/download.png" />
                                            </div>
                                            <div class="oper_down disabled" v-else>
                                                <img src="../../assets/img/account/download.png" />
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page4.total" @onPageChange="onPageChange4" @currtentPageChange="currtentPageChange4"></Page>
                            <div class="search_rsult_txt line_2">{{ page4.pageNo }}-{{ page4.pageSize }} of over {{ page4.total | FilterNum }} results</div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane v-if="obj.tab5 != 0" label="Your Screen Time" name="loginDurationAndTimes" class="result_tab">
                    <div class="table_box">
                        <el-table ref="loginDurationAndTimes" :data="loginDurationAndTimes" border style="width: 100%">
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">No Data</template>
                            <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Month" prop="month" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="User name " prop="userName" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Total # of logins" prop="loginNumber" align="center" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Total screen time" prop="loginTime" align="center" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="View details" align="center" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div class="flex_center">
                                        <el-tooltip class="tbl_opr_tip" :visible-arrow="false" effect="light" content="Details" placement="top">
                                            <div class="oper_down" @click="hanldeDetails(scope.row)">
                                                <img src="../../assets/img/account/detail_ic.png" />
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page5.total" @onPageChange="onPageChange5" @currtentPageChange="currtentPageChange5"></Page>
                            <div class="search_rsult_txt line_2">{{ page5.pageNo }}-{{ page5.pageSize }} of over {{ page5.total | FilterNum }} results</div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <!-- List of login duration and times 弹框 -->
        <el-dialog :visible.sync="timesDetailDialog" center :close-on-click-modal="false" custom-class="dialog_normal" :append-to-body="true">
            <div class="detail_cont">
                <div class="popTitle">{{ detailObj.titleDate }}</div>
                <div class="detail_pop_list">
                    <div class="detail_pop_title_box flex_center">
                        <div class="detail_pop_title">Date</div>
                        <div class="detail_pop_title">Login duration</div>
                        <div class="detail_pop_title">Number of logins</div>
                    </div>
                    <div class="detail_pop_item flex_center" v-for="(item, index) in detailObj.results" :key="index">
                        <div class="detail_pop_txt">{{ item.date }}</div>
                        <div class="detail_pop_txt">{{ item.loginTime }}</div>
                        <div class="detail_pop_txt">{{ item.loginNumber }}</div>
                    </div>
                </div>
                <div class="txt_right">
                    <button class="btn_export" @click="timesDetailDialog = false">Yes</button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getDashboard, getLoginGlance, getOrdersPayList, getOrderList, getOrderview, getRechargeList, getLoginDuration, getLogDurDetails, getPdfAddress } from '@/api/userApi.js';
import Page from '@/components/page';
import { mixins1 } from '@/mixins/index';
export default {
    mixins: [mixins1],
    components: { Page },
    data() {
        return {
            timesDetailDialog: false, //List of login duration and times 弹框
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            tabActiveName: 'LoginAtGlance',
            // obj: {
            //   LastLoginDuration: '',
            //   TotalLogins: '',
            //   AverageDuration: '',
            //   LastViewedQuantity: '',
            //   QuantityOfThisMonth: '',
            //   TotalViews: '',
            //   LastUpdatedQuantity: '',
            //   UpdateQuantityThisMonth: '',
            //   TotalUpdates: '',
            //   LastRechargeAmount: '',
            //   AccumulatedRechargeAmount: '',
            //   RemainingQuota: '',
            //   tab1:0,
            //   tab2:0,
            //   tab3:0,
            //   tab4:0,
            //   tab5:0,
            // },
            loginAtGlanceTable: [],
            UsageList: [],
            page2: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            OrderList: [],
            page3: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            RechargeList: [],
            page4: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            loginDurationAndTimes: [],
            page5: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },

            detailObj: {
                titleDate: '',
                results: [],
            },
            orderCode: '',
            seachinput:'',
        };
    },
    computed: {
        //index传值
        obj: {
            get() {
                return this.$parent.dashboadObj;
            },
            set() {},
        },
    },
    created() {
        //this.dashboadInit()
        this.loginGlance();
    },
   
    // destroyed(){
    // localStorage.clear()
    // },
    methods: {
        async inputcompany() {
            this.getOrdersPayList()
           
        },
        loginGlance() {
            let params = 'page=' + this.page.pageNo + '&pageSize=' + this.page.pageSize;
            getLoginGlance(params).then(result => {
                let data = result;
                if (data && data.code) {
                    // console.log('getLoginGlance', data);
                    this.page.total = data.data.total;
                    this.loginAtGlanceTable = data.data.rows;
                }
            });
        },
        getOrdersPayList() {
            let params = { page: this.page2.pageNo, limit: this.page2.pageSize ,companyName:this.seachinput};
            getOrdersPayList(params).then(result => {
                let data = result;
                if (data && data.code) {
                    // console.log('OrdersPay', data);
                    this.page2.total = data.data.total;
                    this.UsageList = data.data.rows;
                }
            });
        },
        getOrderList() {
            const customerCode = JSON.parse(sessionStorage.getItem('userInfo')) == null ? JSON.parse(localStorage.getItem('userInfo')).userInfo.userCode : JSON.parse(sessionStorage.getItem('userInfo')).userInfo.userCode;
            let params = { page: this.page3.pageNo, limit: this.page3.pageSize, customerCode: customerCode };
            getOrderList(params).then(result => {
                let data = result;
                if (data && data.code) {
                    // console.log('OrdersList', data);
                    this.page3.total = data.data.total;
                    this.OrderList = data.data.rows;
                }
            });
        },
        getRechargeList() {
            let params = { page: this.page4.pageNo, limit: this.page4.pageSize };
            getRechargeList(params).then(result => {
                let data = result;
                if (data && data.code) {
                    // console.log('RechargeList', data);
                    this.page4.total = data.data.total;
                    this.RechargeList = data.data.rows;
                }
            });
        },
        getLoginDurationAndTimes() {
            let params = 'page=' + this.page5.pageNo + '&pageSize=' + this.page5.pageSize;
            getLoginDuration(params).then(result => {
                let data = result;
                if (data && data.code) {
                    // console.log('LoginDuration', data);
                    this.page5.total = data.data.total;
                    this.loginDurationAndTimes = data.data.rows;
                }
            });
        },
        //分页
        onPageChange(pageNo) {
            this.page.pageNo = pageNo;
            this.loginGlance();
        },
        currtentPageChange(pageSize) {
            this.page.pageSize = pageSize;
            this.loginGlance();
        },
        onPageChange2(pageNo) {
            this.page2.pageNo = pageNo;
            this.getOrdersPayList();
        },
        currtentPageChange2(pageSize) {
            this.page2.pageSize = pageSize;
            this.getOrdersPayList();
        },
        onPageChange3(pageNo) {
            this.page3.pageNo = pageNo;
            this.getOrderList();
        },
        currtentPageChange3(pageSize) {
            this.page3.pageSize = pageSize;
            this.getOrderList();
        },
        onPageChange4(pageNo) {
            this.page4.pageNo = pageNo;
            this.getRechargeList();
        },
        currtentPageChange4(pageSize) {
            this.page4.pageSize = pageSize;
            this.getRechargeList();
        },
        onPageChange5(pageNo) {
            this.page5.pageNo = pageNo;
            this.getLoginDurationAndTimes;
        },
        currtentPageChange5(pageSize) {
            this.page5.pageSize = pageSize;
            this.getLoginDurationAndTimes;
        },
        setid(row) {
            sessionStorage.setItem('company', JSON.stringify(row));
            this.$router.push({
                path: '/company/companysnapshot?id3a=' + row.companyAaaid + '&companyCountry=' + row.countryCode,
            });
        },
        setid1(row) {
            sessionStorage.setItem('User', JSON.stringify(row));
            this.$router.push({
                path: '/users/background?pid=' + row.pid + '&name=' + row.userName,
            });
        },
        setid2(row) {
            sessionStorage.setItem('User', JSON.stringify(row));
            this.$router.push({
                path: '/users/background?pid=' + row.pid + '&name=' + row.customerName,
            });
        },
        //tab切换
        handleTabClick() {
            if (this.tabActiveName == 'LoginAtGlance') {
                this.loginGlance();
            } else if (this.tabActiveName == 'UsageList') {
                this.getOrdersPayList();
            } else if (this.tabActiveName == 'OrderList') {
                this.getOrderList();
            } else if (this.tabActiveName == 'RechargeList') {
                this.getRechargeList();
            } else {
                this.getLoginDurationAndTimes();
            }
        },
        //Order List
        async hanldeDown(row) {
            const orderId = row.id;
            await getPdfAddress(orderId).then(res => {
                if (res && res.code) {
                    window.open(res.data, '_blank');
                }
            });
        },

        //List of login duration and times
        async hanldeDetails(row) {
            let params = 'page=' + this.page5.pageNo + '&pageSize=' + this.page5.pageSize + '&month=' + row.month;
            let res = await getLogDurDetails(params);
            if (res && res.code) {
                this.timesDetailDialog = true;

                this.detailObj.titleDate = row.month;
                this.detailObj.results = res.data;
            }
        },
    },
};
</script>
<style scoped>
.das_top_cont {
    /* margin-top: 50px; */
}
.das_top_item {
    position: relative;
    width: 230px;
    height: 232px;
    padding: 20px 20px 10px;
    border-radius: 14px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}
.das_top_item .item_img {
    position: absolute;
    left: 95px;
    top: -20px;
    width: 40px;
    height: 40px;
    line-height: 48px;
    text-align: center;
    border-radius: 50%;
}
.das_top_item .color1 {
    background: #1290c9;
}
.das_top_item .color2 {
    background: #f56b6b;
}
.das_top_item .color3 {
    background: #55b419;
}
.das_top_item .color4 {
    background: #ff7600;
}
.das_top_item .item_img img {
    width: 18px;
    height: 18px;
}
.das_top_item .item_title {
    font-size: 18px;
    color: #022955;
    margin: 10px 0 10px;
    text-align: left;
}
.das_top_item .item_group {
    margin-bottom: 10px;
    font-size: 14px;
}
.das_top_item .item_lab {
    display: block;
    color: #8497ab;
    margin-bottom: 8px;
}
.top_tab {
    margin-top: 40px;
}
.tab_box /deep/ .el-tabs__header {
    margin-bottom: 10px;
}
.el-tabs--card > .el-tabs__header {
    border: none;
}
.tab_box /deep/ .el-tabs__header,
.tab_box /deep/.el-tabs__content {
    width: 100%;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}
.tab_box /deep/ .el-tabs__header {
    padding: 11px 75px;
}
.result_tab {
    padding: 20px;
}
.tab_box /deep/ .el-tabs__nav-wrap {
    width: 100%;
    height: 34px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.tab_box /deep/ .el-tabs__nav {
    width: 100%;
    border: none;
    padding: 7px 12px;
    display: flex;
    justify-content: space-between;
}
.tab_box /deep/ .el-tabs__item {
    padding: 0 5px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #8497ab;
    border: none;
    text-align: center;
}
.tab_box /deep/ .el-tabs__item:last-child {
    padding-right: 15px !important;
}
.tab_box /deep/ .el-tabs__item.is-active {
    width: 220px;
    background: #ff7600;
    box-shadow: 0px 1px 2px 0px #c44306;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Arial Bold';
    color: #ffffff;
}
.tab_box /deep/ .el-table--scrollable-x .el-table__body-wrapper {
    overflow: hidden;
}
.tab_box /deep/ .el-table__body,
.tab_box /deep/ .el-table__footer,
.tab_box /deep/ .el-table__header {
    width: auto !important;
}

.oper_down img {
    width: 18px;
    height: 16px;
}
.oper_down.disabled {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
    opacity: 0.5;
    cursor: no-drop;
}
.detail_pop_list {
    margin: 40px auto 0;
}
.detail_pop_title_box {
    border-bottom: 1px solid #e7eaed;
    padding-bottom: 10px;
}
.detail_pop_item {
    border-bottom: 1px solid #e7eaed;
    padding: 15px 0;
}
.detail_pop_txt,
.detail_pop_title {
    width: 33.3%;
    text-align: center;
    font-size: 16px;
}
.detail_pop_title {
    color: #8497ab;
}
.detail_pop_txt {
    color: #022955;
}
.txt_right {
    text-align: right;
    margin-top: 15px;
}
.title-input /deep/ .el-input__inner {
    border-radius: 15px;
}
</style>
